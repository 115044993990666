@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  @apply font-body tracking-wide
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("./Assets/Font/Montserrat-Medium.ttf");
}


@font-face {
  font-family: "Roboto-Bold";
  src: url("./Assets/Font/Roboto-Bold.ttf");
}

@font-face {
  font-family: "BebasNeue-Regular";
  src: url("./Assets/Font/BebasNeue-Regular.ttf");
}


@font-face {
  font-family: "Roboto-Black";
  src: url("./Assets/Font/Roboto-Black.ttf");
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("./Assets/Font/Roboto-Regular.ttf");
}

.slick-slide>div {
  margin: 0 10px;
}